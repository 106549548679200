'use strict';

window.onload = () => {
	const bannerBlock = $('#main_ban_big');
	const appBody = $('#app_body');

	const switchBannerImage = () => {
		if ($(window).width() > 768) {
			bannerBlock.find('[name="desktopBanner"]').css('display', 'block');
			bannerBlock.find('[name="mobileBanner"]').css('display', 'none');
			appBody.find('[name="desktopRow"]').show();
			appBody.find('[name="mobileRow"]').hide();
		} else {
			bannerBlock.find('[name="desktopBanner"]').css('display', 'none');
			bannerBlock.find('[name="mobileBanner"]').css('display', 'block');
			appBody.find('[name="desktopRow"]').hide();
			appBody.find('[name="mobileRow"]').show();
		}
	};
	switchBannerImage();
	$(window).on('resize', switchBannerImage);
	$('#main_ban_big').slick({
		infinite: true,
		speed: 500,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 4500,
	});

	$('#mobile_image_board')
		.slick({
			infinite: true,
			adaptiveHeight: true,
			centerMode: true,
			speed: 500,
			cssEase: 'linear',
			autoplay: true,
			autoplaySpeed: 4500,
			arrows: false,
			centerPadding: '300px',
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						centerPadding: '20vw',
					},
				},
			],
		})
		.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			if (currentSlide !== nextSlide) {
				const itemList = document.querySelectorAll('.slick-center + .slick-cloned');
				for (let i = 0; i < itemList.length; i++) {
					const next = itemList[i];
					setTimeout(() => {
						next.classList.add('slick-current', 'slick-center');
					}, 0);
				}
			}
		});

	$('#mobile_link').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		speed: 500,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 4500,
		arrows: false,
	});

	const newsMenuBlock = appBody.find('[name="newsMenu"]');
	const newsButton = newsMenuBlock.find('span[name="news"]');
	newsButton.on('click', (e) => {
		e.preventDefault();
		if (newsButton.parent().hasClass('active')) {
			return;
		}
		newsMenuBlock.find('[name="news"]').parent().addClass('active');
		newsMenuBlock.find('[name="familyEvent"]').parent().removeClass('active');
	});

	const familyEventButton = newsMenuBlock.find('span[name="familyEvent"]');
	familyEventButton.on('click', (e) => {
		e.preventDefault();
		if (familyEventButton.parent().hasClass('active')) {
			return;
		}
		newsMenuBlock.find('[name="news"]').parent().removeClass('active');
		newsMenuBlock.find('[name="familyEvent"]').parent().addClass('active');
	});

	$.ajax({
		method: 'get',
		url: '/api/popup',
	}).done((response) => {
		const checkerStr = window.localStorage.getItem('popup-checker');
		const checker = [];
		if (checkerStr) {
			const nowTime = new Date().getTime();
			const itemList = JSON.parse(checkerStr);
			for (let i = 0; i < itemList.length; i++) {
				const item = itemList[i];
				// TODO: 소멸 기간 24시간 제공
				if (nowTime - item.time > 5 * 60 * 1000) {
					// expired
					continue;
				}
				checker.push(item);
			}

			window.localStorage.setItem('popup-checker', JSON.stringify(checker));
		}

		for (let i = 0; i < response.length; i++) {
			const popup = response[i];
			let passed = false;
			for (let j = 0; j < checker.length; j++) {
				if (+popup.id === +checker[j].id) {
					passed = true;
					break;
				}
			}
			if (passed) {
				continue;
			}
			let css = `style="position:fixed; width: ${popup.width}px; height: ${popup.height}px; `;
			if (popup.position === 1) {
				css += `top: 10px; left: 10px;`;
			} else if (popup.position === 2) {
				css += `top: calc(50vh - ${popup.height / 2}px); left: 10px;`;
			} else if (popup.position === 3) {
				css += `bottom: 10px; left: 10px;`;
			} else if (popup.position === 4) {
				css += `top: 10px; left: calc(50vw - ${popup.width / 2}px);`;
			} else if (popup.position === 5) {
				css += `top: calc(50vh - ${popup.height / 2}px); left: calc(50vw - ${popup.width / 2}px);`;
			} else if (popup.position === 6) {
				css += `bottom: 10px; left: calc(50vw - ${popup.width / 2}px);`;
			} else if (popup.position === 7) {
				css += `top: 10px; right: 10px;`;
			} else if (popup.position === 8) {
				css += `top: calc(50vh - ${popup.height / 2}px); right: 10px;`;
			} else if (popup.position === 9) {
				css += `bottom: 10px; right: 10px;`;
			}
			css += '" ';

			const popupBlock = $(`<div ${css}>`).addClass('popup-banner');

			const body = $('<div>').addClass('popup-body');
			if (popup.url) {
				body.on('click', (e) => {
					e.preventDefault();
					window.open(popup.url, '_blank');
				});
			}
			if (popup.imageId) {
				body.append($('<img>').attr('src', `/api/file/view?id=${popup.imageId}`));
			}
			body.append(popup.description);

			const footer = $('<div>').addClass('popup-footer');
			const checkboxBlock = $('<div>').addClass('ui checkbox');
			checkboxBlock.append($('<input type="checkbox" tabindex="0">').addClass('hidden'));
			checkboxBlock.append($('<label>').text('오늘 그만보기'));

			const closeButton = $('<a>').addClass('close-button');
			closeButton.on('click', (e) => {
				e.preventDefault();
				if (checkboxBlock.children('input').prop('checked')) {
					const itemListStr = localStorage.getItem('popup-checker');
					const itemList = itemListStr ? JSON.parse(itemListStr) : [];
					itemList.push({
						id: popup.id,
						time: new Date().getTime(),
					});
					localStorage.setItem('popup-checker', JSON.stringify(itemList));
				}
				popupBlock.remove();
			});
			footer.append(checkboxBlock);
			footer.append(closeButton);

			popupBlock.append(body);
			popupBlock.append(footer);
			$(popupBlock.find('.checkbox')).checkbox();

			$('body').append(popupBlock);
		}
	});
};
